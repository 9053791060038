// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-books-index-js": () => import("./../../../src/pages/en/books/index.js" /* webpackChunkName: "component---src-pages-en-books-index-js" */),
  "component---src-pages-fr-livres-douces-soupes-js": () => import("./../../../src/pages/fr/livres/douces-soupes.js" /* webpackChunkName: "component---src-pages-fr-livres-douces-soupes-js" */),
  "component---src-pages-fr-livres-index-js": () => import("./../../../src/pages/fr/livres/index.js" /* webpackChunkName: "component---src-pages-fr-livres-index-js" */),
  "component---src-templates-index-template-index-template-js": () => import("./../../../src/templates/IndexTemplate/IndexTemplate.js" /* webpackChunkName: "component---src-templates-index-template-index-template-js" */),
  "component---src-templates-page-template-page-template-js": () => import("./../../../src/templates/PageTemplate/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-page-template-js" */),
  "component---src-templates-post-template-post-template-js": () => import("./../../../src/templates/PostTemplate/PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-post-template-js" */),
  "component---src-templates-tag-template-tag-template-js": () => import("./../../../src/templates/TagTemplate/TagTemplate.js" /* webpackChunkName: "component---src-templates-tag-template-tag-template-js" */)
}

